.mention-list {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    z-index: 999;
}

.mention-item {
    padding: 5px;
    cursor: pointer;
}

.mention-item:hover {
    background-color: #eee;
}

.resizable-box {
    width: 20px; 
    height: 100px;
    border: 2px solid #000;
    resize: both;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.8);
    box-sizing: border-box; 
    position: relative; 
  }
  
  .resizable-box.small {
    width: 100px;
    height: 50px;
  }
  
  .resizable-box.medium {
    width: 200px;
    height: 100px;
  }
  
  .resizable-box.large {
    width: 300px;
    height: 150px;
  }