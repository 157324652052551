.ql-snow {
    .ql-picker {
        &.ql-size {

            .ql-picker-label,
            .ql-picker-item {
                &::before {
                    content: attr(data-value) !important;
                }
            }
        }
    }
}

.ql-toolbar {
    display: flex;
    align-items: center;
    padding-bottom: 23px !important;
}

.ql-formats {
    padding-top: 15px;
}

.ql-image {
    display: none !important;
}

.ql-font-THSarabunNew {
    font-family: 'THSarabunNew', sans-serif;
}

.ql-font-serif {
    font-family: serif;
}

.ql-font-NotoSansThai {
    font-family: 'Noto Sans Thai', sans-serif;
}

.ql-picker-item::before {
    content: none !important;
}

.ql-picker-label::before {
    content: none !important;
}

.quill-editor-container {
    width: 100%;
    max-width: 793.7px;
    height: auto;
    aspect-ratio: ƒ 793.7 / 1122.5;
}