
.ql-snow {
  .ql-picker {
    &.ql-size {
      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }
    }
  }
}

.ql-editor {
  font-family: 'THSarabunNew';
  padding: 0; 
  margin: 0; 
  line-height: 1.5;
  line-height: normal;
  font-size: 18px;
}

.ql-editor p {
  margin: 0;
}


#text-editor-container {
  position: relative;
  width: 100%;
  height: 100%;
}

#text-editor-container .ql-editor {
  font-family: 'THSarabunNew';
  overflow: hidden; 
  height: 100%;
  max-height: 100%; 
  box-sizing: border-box; 
  padding: 15px 40px 0px 40px;
}