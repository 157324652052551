/* Header styling */
.manager .rbc-header {
    background-color: rgb(243, 246, 248);
    color: #333;
    padding: 4px;
    font-weight: bold;
}

/* Calendar font and styling */
.manager .rbc-calendar {
    font-family: Poppins, Prompt, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    font-weight: 700;
    color: #333;
}

/* Event styling */
.manager .rbc-event {
    font-size: 12px;
    color: #333;
    border-radius: 15px;
    padding: 1px 4px;
    border: 1px solid #333;
}

/* Remove background and border in month view events */
.manager .rbc-month-row .rbc-event {
    background-color: transparent !important;
    border: 0px solid transparent !important;
}

/* Hide event label (default time indicator) */
.manager .rbc-event-label {
    display: none !important;
}

/* "Show more" button styling */
.manager .rbc-show-more {
    text-decoration: none;
    color: #333;
    text-align: center;
    border: 1px solid #ffd4e4;
    background-color: #ffd4e4;
    border-radius: 15px;
    padding: 1px 4px;
}

/* Event label content alignment */
.manager .rbc-row-segment {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.manager .rbc-show-more {
    display:none;
}
/* Style selected event */
.manager .rbc-event.rbc-selected {
    background-color: #fecf81;
    border: 1px solid #333;
    outline: 0px auto transparent;
}

/* Toolbar header styling */
.manager .rbc-toolbar {
    font-size: 14px;
    flex-wrap: nowrap !important;
    padding: 10px 0px;
}

/* Active toolbar button styling */
.manager .rbc-toolbar .rbc-active button {
    color: #373a3c !important;
}

/* Toolbar button styling */
.manager .rbc-toolbar button {
    font-family: Poppins, Prompt, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    background-color: rgb(70, 203, 226);
}

/* Toolbar button hover effect */
.manager .rbc-toolbar button:hover {
    color: rgb(255, 255, 255);
    border-color: rgb(36, 119, 133);
    background-color: rgb(36, 119, 133);
}

/* Agenda time cell border styling */
.manager .rbc-agenda-time-cell {
    border-left: 1px solid #DDD;
}

/* Month and time view rounded corners and scrollable overflow */
.manager .rbc-month-view,
.manager .rbc-time-view {
    border-radius: 4px;
    overflow: auto;
}

/* Center-align event content */
.manager .rbc-event-content {
    text-align: center;
}
.manager .rbc-event-content[title] {
    pointer-events: none;
}

.manager .rbc-today{
    background-color: rgb(93, 71, 137) !important;
}