
.carousel-container {
  width: 850px;
  height: 400px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 22px;
  display: flex;
  position: relative;
  overflow: visible;
}

@media (max-width: 800px) {
  .carousel-container {
    flex-direction: column;
    height: 250px;
    align-items: center;
    width: 80%;
    padding: 20px 20px;
    margin: 30px 10px;
  }
}

.carousel {
  perspective: 1000px;
  transform-style: preserve-3d;
  width: 100%;
  height: 500px;
  position: relative;
}

.carousel-item {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(0deg);
  transition: transform 1s ease-in-out;
}

.carousel-image {
  width: auto;
  height: 300px;
  object-fit: cover;
}

.carousel-title {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  font-size: 1.5em;
  font-weight: bold;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.5em;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.carousel-container .carousel-item {
  transform: rotateY(calc(90deg * var(--carousel-index)));
}

.carousel-container .carousel-item:nth-child(2) {
  --carousel-index: 1;
}

.carousel-container .carousel-item:nth-child(3) {
  --carousel-index: 2;
}

.carousel-container .carousel-item:nth-child(4) {
  --carousel-index: 3;
}
