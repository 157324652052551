
.rbc-header {
    background-color: rgb(243, 246, 248);
    color: #333;
    padding: 4px;
    font-weight: bold;
}

.rbc-calendar {
    font-family: Poppins, Prompt, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    font-weight: 700;
    color: #333;
}

.rbc-event {
    font-size: 12px;
    background-color: #fecf81 ;
    color: #333;
    border-radius: 15px;
    padding: 1px 4px;
}
.rbc-month-row .rbc-event{
    background-color: #fecf81 ;
}

.rbc-event-label {
    display: none !important;
}

.rbc-show-more{
    text-decoration: none;
    color: #333;
    text-align: center;
    border: 1px solid #ffd4e4;
    background-color: #ffd4e4;
    border-radius: 15px;
    padding: 1px 4px;
}
.rbc-row-segment{
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/* Style selected event */
.rbc-event.rbc-selected {
    background-color: #fecf81;
    border: 1px solid #265985;
}


/* Style the toolbar (header) */
.rbc-toolbar {
    font-size: 14px;
    flex-wrap: nowrap !important;
    padding: 10px 0px;
}

.rbc-toolbar .rbc-active button {
    color: #373a3c !important;
}

.rbc-toolbar button {
    font-family: Poppins, Prompt, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    background-color: rgb(70, 203, 226);
}

.rbc-toolbar button:hover {
    color: rgb(255, 255, 255);
    border-color: rgb(36, 119, 133);
    background-color: rgb(36, 119, 133);
}

.rbc-agenda-time-cell {
    border-left: 1px solid #DDD;
}

.rbc-month-view {
    border-radius: 4px;
    overflow: auto;
}

.rbc-time-view {
    border-radius: 4px;
    overflow: auto;
}

.rbc-event-content{
    text-align: center;
}