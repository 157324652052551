@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ant {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translateX(-2.61814px) translateY(-5.23629px) translateZ(0px)
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes ant2 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translateX(3.99835px) translateY(7.99671px) translateZ(0px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.wrapCircle3D {
  animation: rotating 4s linear infinite;
}

.wrapCoin13D,
.wrapLight,
.wrapTimer,
.wrapCalendar1 {
  animation: ant 3.5s ease-in-out 0s infinite normal;
}

.wrapCloud1,
.wrapCloud2,
.wrapCheckList,
.wrapCalendar {
  animation: ant2 3.5s ease-in-out 0s infinite normal;
}

.container {
  padding: 40px;
  background: #419be0;
}

.slick-list {
  padding: 0px 0px !important;
}

.slick-slide > div > div > .wrap-avatar > .MuiAvatar-root {
  opacity: 0.5;
  width: 48px;
  height: 48px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.slick-center > div > div > .wrap-avatar > .MuiAvatar-root {
  opacity: 1;
  transform: scale(2);
}

.translucentCirclePink, 
.emptyCircleBorder, 
.gradientCirclePurple, 
.gradientCircleBlue, 
.smallGradientPink, 
.smallGradientPurple, 
.smallGradientBlue, 
.translucentCircleWhite {
  animation: ant 3.5s ease-in-out 0s infinite normal;
} 

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logoContainer {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  padding: 40px 0;
  background: white;
}

.logoContainer::before {
  left: 0;
  background: linear-gradient(to left, rbga(255, 255, 255, 0), white);
}
.logoContainer::after {
  right: 0;
  background: linear-gradient(to right, rbga(255, 255, 255, 0), white);
}


.logoSlide {
  display: inline-flex;
  animation: slide 30s linear infinite;
}

.logoSlide img {
  height: 60px;
  margin: 0 30px;
  transition: transform 0.3s ease;
}

body::-webkit-scrollbar {
  width: 12px; /* กำหนดความกว้างของ scroll */
  height: 12px;
 }
 body::-webkit-scrollbar-track {
  background: #EBEAEE; /* สีพื้นหลัง scroll */
 }
 body::-webkit-scrollbar-thumb {
  /* กำหนดการใช้ linear-gradient */
  background: linear-gradient(180deg, rgba(235, 148, 192, 0.7) -0.51%, rgba(194, 176, 230, 0.7) 50.01%, rgba(165, 221, 231, 0.7) 99.49%);
  background-repeat: no-repeat;
  border-radius: 20px; /* กำหนดความโค้งมนของแถบเลื่อน scroll */
  border: 2px solid #EBEAEE; /* กำหนดกรอบเพื่อเว้นระยะรอบแถบเลื่อน scroll */
}

