
  .ql-editor {
    font-family: 'THSarabunNew', Arial, sans-serif;;
  }
  

  
  .ql-snow .ql-picker.ql-font .ql-picker-label::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: attr(data-value);
    font-family: 'THSarabunNew', Arial, sans-serif;;
  }